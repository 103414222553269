import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Card,
  Typography,
  Button,
  Divider,
  Modal,
  withTheme,
  withStyles,
  withWidth,
  Link,
} from "@material-ui/core";
import NavBar from "../../components/Navbar/Navbar";
import Api from "../../Services/Api";
import { MenuSelectors } from "../../Redux/MenuRedux";
import { OrderSelectors } from "../../Redux/OrderRedux";
import RestaurantMenuOutlined from "@material-ui/icons/RestaurantMenuOutlined";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import PolicyOutlined from "@material-ui/icons/PolicyOutlined";
import CancelComponent from "../../components/CancelComponent/CancelComponent";
import CancelationPolicy from "../CancelationPolicy/CancelationPolicy";
import Loading from "../../components/Loading/Loading";
import styles from "./style.module.scss";

const classes = (theme) => ({
  container: {
    backgroundColor: "#fff",
    padding: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  orderButton: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  loginModalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "4px",
    maxWidth: "500px",
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: "pointer",
  },
});

class ShowAppointment extends Component {
  constructor(props) {
    super(props);
    console.log("ShowAppointment props ", props);
    this.state = {
      showCancelModal: false,
      showAppointmentResponse: [],
      showAppointmentSettingResponse: [],
      showAppointmentError: "",
      showCancelationPolicyModal: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.showAppointment();
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.restaurantInfo?.info?.appointment_settings
  //       ?.allow_customer_cancellation !==
  //     this.props?.restaurantInfo?.info?.appointment_settings
  //       ?.allow_customer_cancellation
  //   ) {
  //     this.setState({
  //       allowCustomerCancellation:
  //         this.props?.restaurantInfo?.info?.appointment_settings
  //           ?.allow_customer_cancellation,
  //     });
  //   }
  // }

  handleOpenCancelModal = () => {
    this.setState({
      showCancelModal: true,
    });
  };

  handleCloseCancelModal = () => {
    this.setState({
      showCancelModal: false,
    });
  };
  handleOpenCancelationPolicyModal = () => {
    this.setState({
      showCancelationPolicyModal: true,
    });
  };
  handleCloseCancelationPolicyModal = () => {
    this.setState({
      showCancelationPolicyModal: false,
    });
  };
  handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  showAppointment = async () => {
    const { business_id, appointment_id } = this.props.match.params;

    let apiService = Api.create();

    var requestObj = {
      appointment_id: appointment_id,
      business_id: business_id,
    };

    apiService.showAppointment(requestObj).then((response) => {
      if (response.status === 200) {
        this.setState({
          showAppointmentResponse: response?.data?.appointment || [],
          loading: false,
        });
        this.setState({
          showAppointmentSettingResponse:
            response?.data?.appointment_settings || [],
          loading: false,
        });
        const appointmentData = {
          showAppointmentBusinessId: business_id,
          showAppointmentId: appointment_id,
          showAppointmentResponse: response?.data?.appointment || [],
          showAppointmentSettingsResponse:
            response?.data?.appointment_settings || [],
        };
        localStorage.setItem(
          "showAppointmentData",
          JSON.stringify(appointmentData)
        );
      } else {
        this.setState({ showAppointmentError: response?.data, loading: false });
      }
    });
  };

  redirectToBooking = () => {
    this.props.history.push(
      "/web/" +
        this.state?.showAppointmentResponse?.business_name_url +
        "/" +
        this.state?.showAppointmentResponse?.location_name_url +
        "/rescheduleappointment"
    );
  };

  redirectToOrderHistory = () => {
    this.props.history.push("/orderHistory");
  };

  calculateDaysDifference = (bookingDate) => {
    const currentDate = new Date();
    const differenceInTime = new Date(bookingDate) - currentDate;
    console.log("differenceInTime ", differenceInTime);
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    console.log("differenceInDays ", differenceInDays);
    return differenceInDays;
  };

  render() {
    const { appointment_id } = this.props.match.params;
    const { width } = this.props;
    const isMobile = /xs|sm/.test(width);

    const daysBeforeCancellation = this.calculateDaysDifference(
      this.state?.showAppointmentResponse?.date
    );
    const daysBeforeCancellationRound = Math.floor(daysBeforeCancellation);

    console.log(
      "showAppointmentSettingResponse ",
      this.state.showAppointmentSettingResponse
    );

    console.log(
      "daysBeforeCancellationRound ",
      daysBeforeCancellationRound.toString()
    );
    const appointmentDate = this.state.showAppointmentResponse?.date;
    console.log("appointmentDate ", appointmentDate);
    const currentDate = new Date();
    console.log("currentDate ", currentDate);
    // Check if the current date is after the appointment date
    const isAppointmentPassed = currentDate >= appointmentDate;
    console.log("isAppointmentPassed ", isAppointmentPassed);
    return (
      <>
        {this.state.loading && (
          <Box style={{ minHeight: "calc(100vh - 40px)" }}>
            <Grid
              container
              justify="space-around"
              style={{ height: "calc(100vh - 40px)" }}
              alignItems="center"
              alignContent="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                style={{ maxHeight: "20vh", maxWidth: "20vh" }}
              >
                <Loading title="Please Wait !!!" />
              </Grid>
            </Grid>
          </Box>
        )}
        {!this.state.loading && (
          <Box>
            <NavBar />
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "20px",
                    }}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "500",
                          marginBottom: isMobile ? "10px" : "0",
                        }}
                      >
                        {this.state?.showAppointmentResponse?.business_name}
                      </Typography>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: isMobile ? "17px" : "21px",
                          fontWeight: "500",
                        }}
                      >
                        Appointment
                        <span
                          style={{
                            border: "0.5px solid grey",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            marginLeft: "10px",
                            borderRadius: "4px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          ID #{this.state?.showAppointmentResponse?.id}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Divider />
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "15px",
                  backgroundColor: "#f4f4f4",
                  border: "0.5px solid grey",
                }}
              >
                <Container style={{ padding: "30px" }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="h6"></Typography>
                        </div>
                        <div>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <Grid item>
                              <VerifiedUserOutlined />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6">
                                Customer Details
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>

                    <Divider />

                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Grid item>
                        <Typography variant="subtitle1">
                          Name :{" "}
                          <span style={{ fontWeight: "600" }}>
                            {this.state?.showAppointmentResponse?.customer_name}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Grid>
                        <Typography variant="subtitle1">
                          Phone :{" "}
                          <span style={{ fontWeight: "600" }}>
                            {
                              this.state?.showAppointmentResponse
                                ?.customer_phone
                            }
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Grid>
                        <Typography variant="subtitle1">
                          Email :{" "}
                          <span style={{ fontWeight: "600" }}>
                            {
                              this.state?.showAppointmentResponse
                                ?.customer_email
                            }
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
                <Container>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{ marginBottom: "10px" }}>
                        Booking Details
                      </Typography>
                      <Divider />

                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        style={{ marginTop: "10px" }}
                      >
                        <Grid item xs={12} style={{ padding: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid>
                                  <CalendarTodayOutlined />
                                </Grid>
                                <Grid item>
                                  <Typography variant="h6">
                                    Appointment Details
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            {!isMobile && (
                              <div>
                                <Grid
                                  container
                                  direction="row"
                                  spacing={2}
                                  alignItems="center"
                                >
                                  <Grid style={{ paddingRight: "10px" }}>
                                    <RestaurantMenuOutlined />
                                  </Grid>
                                  <Grid>
                                    <Typography variant="h6">
                                      Service Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Date :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {this.state?.showAppointmentResponse?.date}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Duration :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.duration_minutes
                                    }{" "}
                                    minutes
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Start Time :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.human_start_time
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  End Time :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.human_end_time
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Staff Name :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.staff_name
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Status :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.status
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item style={{ fontWeight: "500" }}>
                                  Notes :
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle1">
                                    {this.state?.showAppointmentResponse?.notes}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                  }}
                                >
                                  {
                                    this.state?.showAppointmentResponse
                                      ?.service_name
                                  }
                                  :{" "}
                                  {
                                    this.state?.showAppointmentResponse
                                      ?.online_sub_total
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="flex-end"
                                style={{ textAlign: "right" }}
                              >
                                <Grid
                                  item
                                  style={{
                                    fontWeight: "500",
                                    textAlign: "right",
                                  }}
                                ></Grid>
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    style={{ textAlign: "right" }}
                                  ></Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "500",
                                  }}
                                >
                                  Tax :{" "}
                                  {this.state?.showAppointmentResponse?.tax}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid style={{ textAlign: "right" }}>
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Tip :{" "}
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.tip_cost
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Divider />

                            <Grid item xs={12}>
                              <Grid style={{ textAlign: "right" }}>
                                <Grid item>
                                  <Typography
                                    variant="subtitle1"
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Total :
                                    {
                                      this.state?.showAppointmentResponse
                                        ?.ssl_amount
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>

                <Grid style={{ margin: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: this.props.isMobile ? "column" : "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={this.redirectToOrderHistory}
                      >
                        Go To History
                      </Button>
                    </div>
                    {this.state.showAppointmentResponse?.status !==
                      "CANCELLED" &&
                      this.state.showAppointmentResponse?.status !==
                        "COMPLETED" && (
                        <div
                          style={{
                            marginTop: this.props.isMobile ? "10px" : "0",
                            display: "flex",
                            flexDirection: this.props.isMobile
                              ? "column"
                              : "row",
                            alignItems: "center",
                          }}
                        >
                          {!isAppointmentPassed &&
                            ((this.state.showAppointmentSettingResponse
                              ?.allow_customer_cancellation === "1" &&
                              daysBeforeCancellationRound.toString() >=
                                this.state?.showAppointmentSettingResponse
                                  ?.days_before_cancellation) ||
                              this.state.showAppointmentSettingResponse
                                ?.days_before_cancellation === "0") && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  style={{
                                    marginBottom: this.props.isMobile
                                      ? "10px"
                                      : "10px",
                                  }}
                                  onClick={this.handleOpenCancelModal}
                                >
                                  Cancel
                                </Button>
                                <div
                                  style={{
                                    marginBottom: this.props.isMobile
                                      ? "15px"
                                      : "0",
                                    marginTop: "7px",
                                    marginRight: "55px",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Grid
                                      style={{
                                        paddingRight: "5px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <PolicyOutlined
                                        style={{ fontSize: 16 }}
                                      />
                                    </Grid>
                                    <Grid>
                                      <Link
                                        variant="outlined"
                                        onClick={
                                          this.handleOpenCancelationPolicyModal
                                        }
                                        component="button"
                                        style={{
                                          fontSize: 15,
                                          color: "black",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Cancelation Policy
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            )}
                          {!isAppointmentPassed && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                color="secondary"
                                variant="outlined"
                                style={{
                                  marginBottom: this.props.isMobile
                                    ? "10px"
                                    : "12px",
                                  marginRight: "8px",
                                }}
                                onClick={this.redirectToBooking}
                              >
                                Reschedule
                              </Button>
                              <Link
                                color="secondary"
                                variant="outlined"
                                onClick={this.cancelationPolicyPage}
                                component="button"
                              >
                                .
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </Grid>
              </Card>
            </Container>
          </Box>
        )}

        <Modal
          open={this.state.showCancelModal}
          onClose={this.handleCloseCancelModal}
        >
          <div className={styles.loginModalStyle} id="modifiers">
            <span
              className={styles.closeIcon}
              onClick={this.handleCloseCancelModal}
            >
              &times;
            </span>
            <CancelComponent
              selectedOrder={this.props.selectedOrder}
              handleCloseCancelModal={() => this.handleCloseCancelModal()}
              appointmentId={appointment_id}
            />
          </div>
        </Modal>
        <Modal
          open={this.state.showCancelationPolicyModal}
          onClose={this.handleCloseCancelationPolicyModal}
          BackdropProps={{
            onClick: this.handleBackdropClick,
          }}
        >
          <div className={styles.loginModalStyle} id="modifiers">
            {/* <span
              className={styles.closeIcon}
              onClick={this.handleCloseCancelationPolicyModal}
            >
              &times;
            </span> */}
            <CancelationPolicy
              selectedOrder={this.props.selectedOrder}
              restaurantInfoData={this.state.showAppointmentSettingResponse}
              handleCloseCancelationPolicyModal={() =>
                this.handleCloseCancelationPolicyModal()
              }
            />
          </div>
        </Modal>
      </>
    );
  }
}

// Map state to props
const mapStateToProps = (state) => {
  console.log("Current state:", state);
  return {
    selectedOrder: state.order.selectedOrder,
    menuItems: MenuSelectors.getMenuItems(state),
    businessId: state.menu.business_id,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    withTheme(withStyles(classes)(withWidth()(ShowAppointment)))
  )
);
