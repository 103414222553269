import apisauce from "apisauce";
import { api_key } from "./apiKey";
import moment from "moment-timezone";
import baseUrl from "./baseUrl";

const api_key_web_menu = "VPJide88NdeK-mhq9H9fvVU8D-nioX7dM2CCXG";

const create = (baseURL = baseUrl()) => {
  const menuApi = apisauce.create({
    baseURL,
  });

  const apiWebMenu = apisauce.create({
    // baseURL: "https://www.orderonthego.ca/restaurant_login/api/web_menu/",
    baseURL: "https://qa.orderonthego.ca/restaurant_login/api/web_menu/",
    timeout: 10000,
  });

  const getMenu = (
    business_id,
    location_id,
    order_type,
    date_time,
    web_menu = false,
    tabValue
  ) => {
    console.log("api tab value ", tabValue);
    let formData = new FormData();
    formData.append("business_id", business_id);
    formData.append("location_id", location_id);
    formData.append("order_type", order_type);
    if (date_time) {
      var momentDate = moment(date_time, "YYYY-MM-DD HH:mm A");

      var scheduledDate = momentDate.format(
        moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
      );
      formData.append("date_time", scheduledDate);
    }
    if (web_menu) {
      formData.append("api_key", api_key_web_menu);
      return apiWebMenu.post("getinfo.php", formData);
    } else {
      formData.append("api_key", api_key);
      return menuApi.post("getinfo.php", formData);
    }
  };
  const getStaffMenu = (business_id, location_id, order_type) => {
    let formData = new FormData();
    formData.append("business_id", business_id);
    formData.append("location_id", location_id);
    formData.append("order_type", order_type);
    formData.append("api_key", api_key);
    return menuApi.post("getinfostaff.php", formData);
  };

  const getMenuDineIn = (business_id, location_id, room_name, table_number) => {
    let formData = new FormData();
    formData.append("business_id", business_id);
    formData.append("location_id", location_id);
    formData.append("room_name", room_name);
    formData.append("table_number", table_number);

    formData.append("api_key", api_key);
    return menuApi.post("getinfodinein.php", formData);
  };

  const sendOrder = (finalOrderJson) => {
    finalOrderJson.api_key = api_key;
    if (finalOrderJson.ssl_token) {
      return menuApi.post(
        "placeorderwithtoken.php",
        JSON.stringify(finalOrderJson)
      );
    } else {
      return menuApi.post("placeorder.php", JSON.stringify(finalOrderJson));
    }
  };
  const sendAppointmentOrder = (finalOrderJson) => {
    finalOrderJson.api_key = api_key;

    return menuApi.post(
      "placeorderwithappointment.php",
      JSON.stringify(finalOrderJson)
    );
  };

  const payOrder = (paymentInfo) => {
    paymentInfo.api_key = api_key;

    if (paymentInfo.ssl_token) {
      return menuApi.post("payorderwithtoken.php", JSON.stringify(paymentInfo));
    } else {
      return menuApi.post("payorder.php", JSON.stringify(paymentInfo));
    }
  };

  const getRestaurantDetails = (requestObj) => {
    requestObj.api_key = api_key;
    return menuApi.post("getrestaurantdetails.php", requestObj);
  };

  const getRestaurantDetailsPhoneOrder = (requestObj) => {
    requestObj.api_key = api_key;
    return menuApi.post("getrestaurantdetailsnew.php", requestObj);
  };

  const getRestaurantDetailsDineIn = (requestObj) => {
    requestObj.api_key = api_key;
    return menuApi.post("getrestaurantdetailsdineine.php", requestObj);
  };

  const getDeliveryFee = (business_id, location_id, latitude, longitude) => {
    var requestObj = {
      business_id: business_id,
      location_id: location_id,
      latitude: latitude,
      longitude: longitude,
      api_key: api_key,
    };
    return menuApi.post("getdeliveryfee.php", requestObj);
  };

  return {
    getMenu,
    getStaffMenu,
    sendOrder,
    sendAppointmentOrder,
    payOrder,
    getRestaurantDetails,
    getRestaurantDetailsPhoneOrder,
    getRestaurantDetailsDineIn,
    getDeliveryFee,
    getMenuDineIn,
  };
};

export default {
  create,
};

// {
//   "token": "fb2b3441c891b614f491a33b032abdb2",
//   "api_key": "W4ylCk0UmIfU-1sw5EFgUQhWj-YpGRxaRxgEnp"
// }
