// a library to wrap and simplify api calls
import apisauce from "apisauce";
import { api_key } from "./apiKey";
import baseUrl from "./baseUrl";

const api_key_web_menu = "VPJide88NdeK-mhq9H9fvVU8D-nioX7dM2CCXG";

// our "constructor"
const create = (baseURL = baseUrl()) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    // headers: {
    //   'Cache-Control': 'no-cache'
    // },
    // 10 second timeout...
    timeout: 10000,
  });

  const apiWebMenu = apisauce.create({
    // baseURL: "https://www.orderonthego.ca/restaurant_login/api/web_menu/",
    baseURL: "https://qa.orderonthego.ca/restaurant_login/api/web_menu/",
    timeout: 10000,
  });

  const DeleteCardApi = (sslToken, apiKey, token) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("token", token);
    formData.append("ssl_token", sslToken);

    return api.post("delete_customer_card.php", formData);
  };

  const getRestaurantList = (requestObj) => {
    requestObj.api_key = api_key;
    return api.post("getrestaurantsnearby.php", requestObj);
  };
  const getOrderHistory = (phoneOrder, requestObj) => {
    const formData = new FormData();
    if (phoneOrder) {
      formData.append("business_id", requestObj.business_id);
      formData.append("api_key", api_key);
      formData.append("location_id", requestObj.location_id);
    } else {
      formData.append("customer_id", requestObj.customer_id);
      formData.append("api_key", api_key);
      formData.append("token", requestObj.token);
    }

    return api.post("order_history.php", formData);
  };

  const getfavouriteRestaurantList = (token) =>
    api.post("favouriterestaurantlist.php", {
      api_key,
      token,
    });
  const addFavourite = (business_id, location_id, token) =>
    api.post("addfavouriterestaurant.php", {
      api_key,
      token,
      business_id,
      location_id,
    });
  const removeFavourite = (business_id, location_id, token) =>
    api.post("removefavouriterestaurantlist.php", {
      api_key,
      token,
      business_id,
      location_id,
    });

  const getLatestUserInfo = (customer_id, token) => {
    const formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("api_key", api_key);
    formData.append("token", token);
    return api.post("getcutomerinfo.php", formData);
  };

  const getOrderStatus = (business_id, location_id, order_number) =>
    api.post("get_order_status.php", {
      api_key,
      order_number,
      business_id,
      location_id,
    });

  const checkCoupon = (coupon, business_id, location_id, token) =>
    api.post("validate_coupon.php", {
      business_id,
      location_id,
      coupon_code: coupon,
      api_key,
      token,
    });

  const getRestaurantInfo = (name, location = null) => {
    const formData = new FormData();
    formData.append("business_name", name);
    formData.append("api_key", api_key);
    formData.append("business_location", location);
    return api.post("getrestaurantbyname.php", formData);
  };

  const getRestaurantInfoWebMenu = (name, location = null) => {
    const formData = new FormData();
    formData.append("business_name", name);
    formData.append("api_key", api_key_web_menu);
    formData.append("business_location", location);
    return apiWebMenu.post("getrestaurantbyname.php", formData);
  };

  const getRestaurantInfoDineIn = (
    name,
    roomName,
    tableNumber,
    location = null
  ) => {
    const formData = new FormData();
    formData.append("business_name", name);
    formData.append("api_key", api_key);
    formData.append("business_location", location);
    formData.append("table_number", tableNumber);
    formData.append("room_name", roomName);
    return api.post("getrestaurantbynamedinein.php", formData);
  };

  const addVisitorInfo = (requestObj) => {
    requestObj.api_key = api_key_web_menu;
    return apiWebMenu.post("savevisitorslist.php", JSON.stringify(requestObj));
  };

  const getTimeSlots = (requestObj) => {
    // const storedTabValue = localStorage.getItem("tabValue");
    // console.log("getTimeSlots storedTabValue ", storedTabValue);
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("business_id", requestObj.business_id);
    formData.append("service_id", requestObj.service_id);
    formData.append("date", requestObj.date);
    formData.append("staff_id", requestObj.staff_id);
    // Conditionally add staff_id based on storedTabValue
    // if (storedTabValue === "1") {
    
    // }

    return api.post("gettimeslots.php", formData);
  };

  const createAppointment = (requestObj) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("business_id", requestObj.business_id);
    formData.append("location_id", requestObj.location_id);
    formData.append("notes", requestObj.notes);
    formData.append("service_id", requestObj.service_id);
    formData.append("date", requestObj.date);
    formData.append("start_time", requestObj.start_time);
    formData.append("customer_name", requestObj.customer_name);
    formData.append("customer_email", requestObj.customer_email);
    formData.append("customer_phone", requestObj.customer_phone);
    formData.append("staff_id", requestObj.staff_id);
    formData.append("variation_id", requestObj.variation_id);
    return api.post("createappointment.php", formData);
  };

  const cancelAppointment = (requestObj) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("appointment_id", requestObj.appointment_id);
    formData.append("business_id", requestObj.business_id);

    return api.post("cancelappintment.php", formData);
  };

  const rescheduleAppointment = (requestObj) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("appointment_id", requestObj.appointment_id);
    formData.append("date", requestObj.date);
    formData.append("start_time", requestObj.start_time);
    formData.append("service_id", requestObj.service_id);
    formData.append("staff_id", requestObj.staff_id);
    formData.append("business_id", requestObj.business_id);

    return api.post("rescheduleappointment.php", formData);
  };

  const showAppointment = (requestObj) => {
    const formData = new FormData();
    formData.append("api_key", api_key);
    formData.append("appointment_id", requestObj.appointment_id);
    formData.append("business_id", requestObj.business_id);

    return api.post("showappointment.php", formData);
  };
  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRestaurantList,
    getOrderHistory,
    getLatestUserInfo,
    getfavouriteRestaurantList,
    addFavourite,
    removeFavourite,
    getOrderStatus,
    checkCoupon,
    getRestaurantInfo,
    getRestaurantInfoWebMenu,
    getRestaurantInfoDineIn,
    addVisitorInfo,
    DeleteCardApi,
    getTimeSlots,
    createAppointment,
    cancelAppointment,
    rescheduleAppointment,
    showAppointment,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
